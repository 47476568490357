export const getZone = /* GraphQL */ `
    query GetZone($id: ID!) {
        getZone(id: $id) {
            id
            name
            counties
            state {
                enabled
            }
            createdAt
            updatedAt
        }
    }
`;

export const updateZone = /* GraphQL */ `
    mutation UpdateZone(
        $input: UpdateZoneInput!
        $condition: ModelZoneConditionInput
    ) {
        updateZone(input: $input, condition: $condition) {
            id
            name
            counties
            state {
                enabled
            }
        }
    }
`;
