<template>
  <page-layout ref="layout">
    <template #breadcrumbs>
      <b-breadcrumb-item text="Management" />
      <b-breadcrumb-item text="Zones" :to="{ name: 'management-zones' }" />
      <b-breadcrumb-item :text="zone.name" active />
    </template>

    <template #actions="{ state }">
      <can do="update" on="management-zone">
        <b-button v-if="state.editing" v-b-tooltip="'Update'" variant="transparent" size="sm" @click="updateZone">
          <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" />
        </b-button>
      </can>
    </template>

    <template #dropdown-options="{ state }">
      <can do="update" on="management-zone">
        <b-dropdown-item @click="state.editing = !state.editing">
          <feather-icon icon="EditIcon"/>
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>
        <b-dropdown-item @click="updateState">
          <font-awesome-icon :icon="['fas', zone.state.enabled ? 'toggle-off' : 'toggle-on']"></font-awesome-icon>
          <span class="align-middle ml-50">{{ zone.state.enabled ? 'Disable' : 'Enable' }}</span>
        </b-dropdown-item>
        <can do="delete" on="management-zone">
          <b-dropdown-item @click="$refs.layout.confirmDelete(zone, deleteZone, cascadeConfirmDeleteOptions)">
            <feather-icon icon="Trash2Icon"/>
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </can>
        <b-dropdown-divider/>
      </can>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content="{ state }">
      <validation-observer ref="observer" tag="div">
        <form ref="form">
          <b-row>
            <b-col cols="6">
              <b-card>
                <b-row>
                  <b-col align-self="center" cols="auto">
                    <b-avatar v-if="hasCustomAvatar(zone.avatar)" v-b-modal.avatar-modal variant="primary" size="8em" badge-variant="white" :disabled="!state.editing">
                      <b-img fluid fluid-grow :src="zone.avatar.src" :alt="zone.avatar.name"></b-img>
                      <template #badge>
                        <b-icon :icon="enabledIcon(zone.state.enabled)" :variant="enabledIconVariant(zone.state.enabled)"></b-icon>
                      </template>
                    </b-avatar>
                    <b-avatar v-else v-b-modal.avatar-modal variant="primary" size="6em" badge-variant="white" :disabled="!state.editing">
                      <font-awesome-icon :icon="icon" size="3x"></font-awesome-icon>
                      <template #badge>
                        <b-icon :icon="enabledIcon(zone.state.enabled)" :variant="enabledIconVariant(zone.state.enabled)"></b-icon>
                      </template>
                    </b-avatar>
                    <avatar-modal v-if="state.editing"
                                  title="Zone Avatar"
                                  :avatar="zone.avatar"
                                  @update-avatar="updateAvatar"/>
                  </b-col>
                  <b-col align-self="center">
                    <validation-provider v-slot="validationContext" vid="name" name="Zone Name" rules="required">
                        <b-form-group label="Zone Name" label-for="name-input" :invalid-feedback="validationContext.errors[0]">
                          <b-input v-model="zone.name" placeholder="Zone Name" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-input>
                        </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col>
              <b-card>
                <label>Counties</label>
                <b-form-tags v-model="zone.counties" no-outer-focus class="form-control-plaintext" duplicate-tag-text="No duplicate counties">
                  <template v-slot="{ tags, inputAttrs, inputHandlers, tagVariant, addTag, removeTag }">
                    <b-input-group class="mb-2">
                      <b-form-input v-bind="inputAttrs" placeholder="New County" class="form-control" :disabled="!state.editing" v-on="inputHandlers"></b-form-input>
                      <b-input-group-append>
                        <b-button variant="primary" :disabled="!state.editing" @click="addTag()" >Add</b-button>
                      </b-input-group-append>
                    </b-input-group>

                    <div v-if="tags.length > 0" class="d-inline-block" style="font-size: 1.25rem;">
                      <b-form-tag v-for="tag in tags" :key="tag" :title="tag" :variant="tagVariant" :no-remove="!state.editing" @remove="removeTag(tag)">
                        {{ tag }}
                      </b-form-tag>
                    </div>
                    <b-form-text v-else>
                      No Counties associated to this zone.
                    </b-form-text>

                  </template>
                </b-form-tags>
              </b-card>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </template>

    <template #debug>
      <debug title="Zone" collapsed>{{ zone }}</debug>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import AvatarModal from '@/components/Avatar/AvatarModal.vue';
import avatar from '@/mixins/avatar.mixin'
import role from '@/mixins/role.mixin'
import status from '@/mixins/status.mixin'
import print from '@/mixins/print.mixin';
import notify from '@/mixins/notify.mixin';
import {API, graphqlOperation} from 'aws-amplify';
import {getZone, updateZone} from './queries/zone';
import { cascadeDeleteZone, cascadeConfirmDeleteOptions} from '@/graphql/cascade/zone';

export default {
  components: {
    PageLayout,
    AvatarModal,
  },
  mixins: [ avatar, role, status, print, notify ],
  props: {
    id: {
      type: String,
      required: false,
      default: null
    },
    slug: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      zone: null,
      validation: {
        duplicateTags: []
      },
      icon: 'fa-solid fa-map',
      cascadeConfirmDeleteOptions
    }
  },
  async mounted() {
    await this.getZone()
  },
  methods: {
    async getZone() {
      if(this.id) {
        const response = await API.graphql(graphqlOperation(getZone, { id: this.id }));
        this.zone = response.data.getZone
      }
      this.$refs.layout.state.loading = false
    },
    async patchZone(input) {
      try {
        const response = await API.graphql(graphqlOperation(updateZone, { input: input } ));
        this.zone = response.data.updateZone;
        this.notify({ title: 'Success', text: 'Zone was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        this.notify({ title: 'Error', text: 'Zone failed to update', icon: this.icon, variant: 'danger'});
      }
    },
    async updateZone() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        await this.patchZone({
          id: this.zone.id,
          name: this.zone.name,
          counties: this.zone.counties
        })
      }
      else {
        this.notify({ title: 'Warning', text: 'Zone failed to update. Missing required fields.', icon: this.icon, variant: 'warning'});
      }
    },
    async deleteZone(zone, swalCallback) {
      try {
        await this.cascadeDeleteZone(zone.id, swalCallback)
        await this.notify({ title: 'Success', text: 'Zone was successfully deleted', icon: this.icon, variant: 'success' });
        await this.$router.push({ name: 'management-zones' })
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Zone failed to delete', icon: this.icon, variant: 'danger'});
        throw error //for swal
      }
    },
    cascadeDeleteZone,

    // eslint-disable-next-line no-shadow
    async updateAvatar(avatar) {
      this.zone.avatar = avatar;
      await this.patchZone({ id: this.zone.id, avatar: this.zone.avatar })
    },
    async updateState() {
      this.zone.state.enabled = !this.zone.state.enabled;
      await this.patchZone({ id: this.zone.id, state: this.zone.state })
    },

    async refresh() {
      this.$refs.layout.state.loading = true
      await this.getZone()
      this.$refs.layout.state.loading = false
    },
    getValidationState({ dirty, validated, valid = null }) {
      if(this.$refs.layout.state.editing) {
        return dirty || validated ? valid : null;
      }
      return null;
    },
  },
}
</script>

<style lang="scss">
  @import '~@core/scss/vue/libs/vue-select.scss';

  .per-page-selector {
    min-width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
</style>
